<template>
  <div>
    <h4 class="card-label border-bottom mb-3">Tables List</h4>
    <div class="loader-wrapper" v-if="loader">
      <div class="image-loader"></div>
    </div>
    <div class="form-group">
      <div class="card-body">
        <div class="row">
          <div class="text-center">
            <b-button
              v-if="permission.is_add == 1"
              @click="addTable"
              class="btn custom-btn-style"
              v-b-modal.modal-standard
              variant=":class"
              >Add Table
            </b-button>
            <b-modal
              hide-footer
              id="modal-standard"
              :title="table_title"
              title-class="font-18"
              ref="modal"
              @show="resetModal"
              @hidden="resetModal"
            >
              <form ref="form" @submit.stop.prevent="handleSubmit">
                <div class="form-group">
                  <label>Select Floor</label>
                  <div>
                    <select
                      v-model="floor_uuid"
                      required
                      name="floor"
                      class="form-control-role input-style"
                    >
                      <option value="">Select Floor</option>
                      <option
                        v-for="floor in floors"
                        :value="floor.uuid"
                        v-bind:key="floor.uuid"
                        :title="floor.floor"
                      >
                        {{ floor.floor }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-group">
                  <!--                  <label>Custom Floor</label>-->
                  <div>
                    <input
                      type="text"
                      placeholder="Custom Floor"
                      v-model="custom_floor"
                      class="form-control input-style"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label>Select Area</label>
                  <div>
                    <select
                      v-model="area_uuid"
                      required
                      name="floor"
                      class="form-control-role input-style"
                    >
                      <option value="">Select Area</option>
                      <option
                        v-for="area in areas"
                        :value="area.uuid"
                        v-bind:key="area.uuid"
                        :title="area.area"
                      >
                        {{ area.area }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-group">
                  <!--                  <label>Custom Area</label>-->
                  <div>
                    <input
                      type="text"
                      placeholder="Custom Area"
                      v-model="custom_area"
                      class="form-control input-style"
                    />
                  </div>
                </div>
                <div>
                  <b-form-group label="Type" v-show="!is_edit">
                    <div
                      class="d-flex justify-content-start align-items-center"
                    >
                      <b-form-radio
                        v-model="add_type"
                        name="add_type-radios"
                        value="1"
                        >Bulk Add
                      </b-form-radio>
                      <b-form-radio
                        v-model="add_type"
                        name="add_type-radios"
                        value="0"
                        class="ml-3"
                        >Single table
                      </b-form-radio>
                    </div>
                  </b-form-group>
                </div>
                <div v-if="add_type === bulk_add && !is_edit">
                  <b-form-group
                    :state="quantityState"
                    label="Quantity"
                    label-for="quantity-input"
                    invalid-feedback="Quantity is required"
                  >
                    <b-form-input
                      type="number"
                      min="1"
                      id="quantity-input"
                      v-model="quantity"
                      :state="quantityState"
                      class="input-style"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div v-if="add_type === single_add || is_edit">
                  <b-form-group
                    label="Table Name"
                    label-for="custom-name-input"
                    invalid-feedback="Table Name is required"
                  >
                    <b-form-input
                      id="custom-name-input"
                      v-model="table_name"
                      class="input-style"
                      type="number"
                      min="1"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div v-if="add_type === single_add || is_edit">
                  <b-form-group
                    label="Display Name"
                    label-for="custom-name-input"
                    invalid-feedback="Display Name is required"
                  >
                    <b-form-input
                      id="custom-name-input"
                      v-model="display_name"
                      class="input-style"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div>
                  <b-form-group
                    :state="capacityState"
                    label="Capacity"
                    label-for="capacity-input"
                    invalid-feedback="Capacity is required"
                  >
                    <b-form-input
                      type="number"
                      min="1"
                      max="12"
                      id="capacity-input"
                      v-model="capacity"
                      :state="capacityState"
                      class="input-style"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div v-if="add_type === single_add || is_edit">
                  <b-form-group label="Has Quick Service">
                    <div
                      class="d-flex justify-content-start align-items-center"
                    >
                      <b-form-radio
                        v-model="quick_service"
                        name="some-radios"
                        value="1"
                        >Yes
                      </b-form-radio>
                      <b-form-radio
                        v-model="quick_service"
                        name="some-radios"
                        value="0"
                        class="ml-3"
                        >No
                      </b-form-radio>
                    </div>
                  </b-form-group>
                </div>
                <div class="col-md-12 p-0">
                  <div class="form-group users-notification-wrapper">
                    <label>Assign Waiter</label>
                    <select2-multiple-control
                      class="form-control-role"
                      v-model="assign_waiter"
                      :options="waiters"
                      @change="myChangeEvent($event)"
                      @select="mySelectEvent($event)"
                    />
                  </div>
                </div>
                <b-button
                  type="button"
                  class="mt-3 btn-orange pull-right"
                  @click="submitTable"
                  :disabled="isFormValid"
                  >Add
                </b-button>
              </form>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
    <div class="row align-items-center mb-3">
      <div class="col-sm-12 col-md-3">
        <div class="dataTables_length">
          <label class="d-inline-flex align-items-center mb-0">
            Show
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select>
            entries </label
          ><br />
          <button
            @click="deleteBulkTables"
            :disabled="isBulkDeletedButton"
            class="btn btn-danger rounded-pill mt-2"
          >
            Delete Bulk Tables
          </button>
        </div>
      </div>
      <!-- Search -->
      <div class="col-sm-12 col-md-9">
        <div class="dataTables_filter filter-dataTables text-md-right">
          <label class="d-inline-flex align-items-center mb-0">
            Search:
            <b-form-input
              v-model="filter"
              type="search"
              placeholder="Search..."
              class="form-control form-control-sm ml-2"
            ></b-form-input>
          </label>
          <div class="ml-2">
            <button class="btn print excel-btn" @click="excelExport()">
              Export to Excel
            </button>
            <button class="btn print" @click="csvExport(csvData)">
              Export to CSV
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End search -->
    <div class="card">
      <div class="card-body p-0">
        <div class="row mb-md-2"></div>
        <!-- Table -->
        <div class="table-responsive mb-0">
          <b-table
            :items="tables"
            :fields="table_fields"
            responsive="true"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-desc.sync="sortDesc"
            ref="selectableTable"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #head(uuid)>
              <input v-model="isSelectAll" type="checkbox" />
            </template>
            <template v-slot:cell(uuid)="tables">
              <input
                type="checkbox"
                :value="tables.item.uuid"
                v-model="checkedNames"
              />
            </template>
            <template v-slot:cell(select)="tables"> </template>
            <template v-slot:cell(floor)="tables">
              <div>{{ tables.item.floor.floor }}</div>
            </template>
            <template v-slot:cell(area)="tables">
              <div>{{ tables.item.area.area }}</div>
            </template>
            <template v-slot:cell(has_quick_service)="tables">
              <b-badge
                v-if="tables.item.has_quick_service === 1"
                class="mr-1 badge-font"
                pill
                variant="success"
                >Yes
              </b-badge>
              <b-badge v-else class="mr-1 badge-font" pill variant="danger"
                >No
              </b-badge>
            </template>
            <template v-slot:cell(staff)="tables">
              <div>{{ staffNameList(tables.item.staff) }}</div>
            </template>
            <template v-slot:cell(qr_code_src)="tables">
              <a
                :href="tables.item.qr_code_src"
                target="_blank"
                @click="downloadItem(tables.item)"
              >
                <img
                  :src="tables.item.qr_code_src"
                  style="width: 50px; height: 50px"
                />
              </a>
            </template>
            <template v-slot:cell(status)="tables">
              <b-badge
                v-if="tables.item.is_occupied === 1"
                class="mr-1 badge-font"
                pill
                variant="success"
                >Reserved
              </b-badge>
              <b-badge v-else class="mr-1 badge-font" pill variant="success"
                >Available
              </b-badge>
            </template>
            <template v-slot:cell(action)="tables">
              <div class="position-relative">
                <div class="table-actions">
                  <a
                    v-if="permission.is_edit == 1"
                    href="javascript:void(0)"
                    class="success-icon"
                    @click="editModal(tables)"
                    v-b-modal.modal-standard
                  >
                    <i class="fas fa-pencil-alt"></i>
                  </a>
                  <a
                    v-if="permission.is_deleted == 1"
                    href="javascript:void(0)"
                    class="reject-icon"
                    @click="deleteTable(tables)"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </a>
                </div>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import XLSX from "xlsx";
import Select2MultipleControl from "v-select2-multiple-component";
import helpers from "../../helpers";
import axios from "axios";

export default {
  name: "RestaurantSettings",
  data() {
    return {
      permission: {
        is_add: 1,
        is_deleted: 1,
        is_edit: 1,
        web_access_enabled: 1
      },
      loader: false,
      search: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: true,
      tables: [],
      floors: [],
      areas: [],
      waiters: [],
      multiple_tables: [],
      uniqueIds: [],
      table_uuid: "",
      floor_uuid: "",
      custom_floor: "",
      area_uuid: "",
      custom_area: "",
      table_name: "",
      display_name: "",
      capacity: "",
      quantity: "",
      assign_waiter: [],
      quick_service: "",
      capacityState: null,
      quantityState: null,
      DropDownMenu: false,
      disabled: true,
      uuid: "",
      add_type: "",
      bulk_add: "1",
      single_add: "0",
      restaurantId: this.$route.params.restaurant,
      restaurantUuid: this.$route.params.restaurant_uuid,
      table_fields: [
        { key: "uuid" },
        { key: "floor", label: "Floor" },
        { key: "area", label: "Area" },
        { key: "table_name", label: "Table Name" },
        { key: "display_name", label: "Display Name" },
        { key: "capacity" },
        { key: "staff", label: "Assigned" },
        { key: "has_quick_service", label: "Quick Service" },
        { key: "qr_code_src" },
        { key: "status", label: "Status" },
        { key: "created_at", label: "Date" },
        { key: "action" }
      ],
      is_edit: false,
      table_title: "Add Table",
      isSelectAll: false,
      checkedNames: []
    };
  },
  components: { Select2MultipleControl },
  computed: {
    rows() {
      return this.tables.length;
    },
    csvData() {
      return this.tables.map(item => ({
        ...item
      }));
    },
    isBulkDeletedButton() {
      return this.checkedNames.length === 0;
    },
    isFormValid() {
      return !(
        this.floor_uuid !== "" &&
        this.area_uuid !== "" &&
        this.capacity !== "" &&
        this.assign_waiter !== ""
      );
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    onPreviewClick(value) {
      var uniqueData = [];
      $.each(this.multiple_tables, function(i, el) {
        if ($.inArray(el, uniqueData) === -1) uniqueData.push(el);
      });
      this.uniqueIds = uniqueData;

      // console.log(this.uniqueIds);

      if (this.multiple_tables.length > 0) {
        this.disabled = false;
      }
    },
    myChangeEvent(val) {
      this.assign_waiter = val;
    },
    // eslint-disable-next-line no-unused-vars
    mySelectEvent({ id, text }) {},
    forceFileDownload(response, tableData) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", tableData.display_name + ".png"); //or any other extension
      document.body.appendChild(link);
      link.click();
    },
    downloadItem(url) {
      axios
        .get(url.qr_code_src, {
          responseType: "arraybuffer",
          headers: {
            "Access-Control-Allow-Origin": "*"
          }
        })
        .then(response => {
          this.forceFileDownload(response, url);
        })
        .catch();
    },

    staffNameList(staff) {
      let name = [];
      staff.map(x => name.push(x.name));
      return name;
    },

    // downloadItem(url) {
    //   let qrCode = url.qr_code_src;
    //   console.log(qrCode);
    //   let customDisplayName = url.display_name;
    //   // var base64 = ApiService.get(qrCode, {
    //   //   responseType: "arraybuffer"
    //   // }).then(response =>
    //   //   Buffer.from(response.data, "binary").toString("base64")
    //   // );
    //   // var img = new Image();
    //   // img.src = "data:image/jpeg;base64, " + base64;
    //   // var fileURL = window.URL.createObjectURL(img);
    //   // console.log(fileURL);
    //   // const link = document.createElement("a");
    //   // link.href = fileURL;
    //   // link.setAttribute("download", customDisplayName + ".png");
    //   // document.body.appendChild(link);
    //   // link.click();
    //   ApiService.get(qrCode, {
    //     responseType: "arraybuffer",
    //     headers: {
    //       "Access-Control-Allow-Origin": "*"
    //     }
    //   })
    //     .then(response => {
    //       var fileURL = window.URL.createObjectURL(new Blob([response.data]));
    //       console.log(response);
    //       const link = document.createElement("a");
    //       link.href = fileURL;
    //       link.setAttribute("download", customDisplayName + ".png");
    //       console.log("link", link);
    //       document.body.appendChild(link);
    //       link.click();
    //     })
    //     .catch();
    // },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.capacityState = valid;
      return valid;
    },
    addTable() {
      this.floor_uuid = "";
      this.custom_floor = "";
      this.area_uuid = "";
      this.custom_area = "";
      this.table_name = "";
      this.display_name = "";
      this.capacity = "";
      this.quick_service = "";
      this.assign_waiter = "";
      this.capacityState = null;
      this.is_edit = false;
      this.table_title = "Add Table";
    },
    resetModal() {},
    submitTable() {
      var obj = {
        floor_uuid: this.floor_uuid,
        custom_floor: this.custom_floor,
        area_uuid: this.area_uuid,
        custom_area: this.custom_area,
        add_type: this.add_type,
        quantity: this.quantity,
        table_name: this.table_name,
        display_name: this.display_name,
        capacity: this.capacity,
        has_quick_service: this.quick_service,
        assign_waiter: this.assign_waiter
      };

      this.is_edit ? this.updateTableApi(obj) : this.addTableApi(obj);

      // Prevent modal from closing
    },
    addTableApi(obj) {
      this.loader = true;
      ApiService.post(
        this.$path + "/admin/table/restaurant/" + this.restaurantUuid,
        obj
      )
        .then(response => {
          helpers.successMessages(this, response.data.message);
          this.getRestaurantTables();
          this.$refs["modal"].hide();
          this.loader = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    updateTableApi(obj) {
      ApiService.patch(
        this.$path +
          "/admin/table/restaurant/" +
          this.restaurantUuid +
          "/" +
          this.table_uuid,
        obj
      )
        .then(response => {
          helpers.successMessages(this, response.data.message);
          let index = this.tables.findIndex(
            x => x.uuid === response.data.data.uuid
          );
          if (index !== -1) {
            this.tables[index].floor = response.data.data.floor;
            this.tables[index].area = response.data.data.area;
            this.tables[index].table_name = response.data.data.table_name;
            this.tables[index].display_name = response.data.data.display_name;
            this.tables[index].capacity = response.data.data.capacity;
            this.tables[index].staff = [];
            this.tables[index].staff = response.data.data.staff;
            this.tables[index].has_quick_service =
              response.data.data.has_quick_service;
            this.tables[index].qr_code_src = response.data.data.qr_code_src;
            this.tables[index].status = response.data.data.status;
            this.tables[index].created_at = response.data.data.created_at;
          }
          this.$refs["modal"].hide();
          this.loader = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-standard");
      });
    },
    deleteBulkTables() {
      let obj = { table_uuids: this.checkedNames };
      axios
        .delete(this.$path + "/admin/table/restaurant/" + this.restaurantUuid, {
          data: obj
        })
        .then(response => {
          helpers.successMessages(this, response.data.message);
          this.getRestaurantTables();
          this.loader = false;
          this.checkedNames = [];
          this.isSelectAll = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    getFloors() {
      this.loader = true;
      axios
        .get(this.$path + "/admin/platform/floor")
        .then(response => {
          this.floors = response.data.data;
          this.loader = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    getWaiters() {
      this.loader = true;
      axios
        .get(this.$path + "/admin/staff/waiters/" + this.restaurantUuid)
        .then(response => {
          response.data.data.map(x => {
            this.waiters.push({ id: x.uuid, text: x.name });
          });

          this.loader = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    getAreas() {
      this.loader = true;
      axios
        .get(this.$path + "/admin/platform/area")
        .then(response => {
          this.areas = response.data.data;
          this.loader = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    getRestaurantTables() {
      this.loader = true;
      axios
        .get(this.$path + "/admin/table/restaurant/" + this.restaurantUuid)
        .then(response => {
          this.tables = response.data.data;
          this.loader = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    clickDots(uuid) {
      this.uuid = uuid;
      if (this.DropDownMenu) {
        this.DropDownMenu = false;
      } else {
        this.DropDownMenu = true;
      }
    },
    deleteTable(table) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          this.loader = true;
          ApiService.delete(
            this.$path +
              "/admin/table/restaurant/" +
              this.restaurantUuid +
              "/" +
              table.item.uuid
          )
            .then(response => {
              helpers.successMessages(this, response.data.message);
              let index = this.tables.findIndex(
                x => x.uuid === response.data.data.uuid
              );
              if (index !== -1) {
                this.tables.splice(index, 1);
              }
              this.loader = false;
            })
            .catch(err => {
              this.loader = false;
              if (err.response.data.status === 400) {
                helpers.errorOther(this, err.response.data);
              }
              if (err.response.data.status === 422) {
                helpers.validationError(this, err.response.data.errors);
              }
              if (err.response.status === 404) {
                helpers.errorMessage(this, "url not found");
              }
              if (err.response.status === 500) {
                helpers.serverError(this);
              }
            });
        }
        this.loader = false;
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    // downloadQR(table) {
    //   ApiService.get(table.item.qr_code).then(response => {
    //     var fileURL = window.URL.createObjectURL(new Blob([response.data]));
    //     var fileLink = document.createElement("a");
    //
    //     fileLink.href = fileURL;
    //     fileLink.setAttribute("download", "qr_code.png");
    //     document.body.appendChild(fileLink);
    //
    //     fileLink.click();
    //   });
    // },
    editModal(table) {
      this.capacityState = null;
      this.is_edit = true;
      this.table_title = "Edit Table";
      this.floor_uuid = table.item.floor.uuid;
      this.area_uuid = table.item.area.uuid;
      this.table_name = table.item.table_name;
      this.display_name = table.item.display_name;
      this.capacity = table.item.capacity;
      this.quick_service = table.item.has_quick_service;
      table.item.staff.map(x => {
        this.assign_waiter.push(x.uuid);
      });
      this.table_uuid = table.item.uuid;
    },
    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(";"),
        ...arrData.map(item => Object.values(item).join(";"))
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "Tables.csv");
      link.click();
    },
    excelExport() {
      const data = XLSX.utils.json_to_sheet(this.tables);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "Tables.xlsx");
    },
    closeMenu() {
      this.DropDownMenu = false;
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.closeMenu();
      }
    }
  },
  watch: {
    isSelectAll: function(val) {
      if (val) {
        this.checkedNames = this.$refs.selectableTable.paginatedItems.map(
          x => x.uuid
        );
      } else {
        this.checkedNames = [];
      }
    }
  },
  mounted() {
    document.addEventListener("click", this.close);
    this.restaurantData = JSON.parse(localStorage.getItem("restaurantDetails"));
    this.restaurantId = this.restaurantData.id;
    this.restaurantUuid = this.restaurantData.uuid;
    this.getRestaurantTables();
    this.getFloors();
    this.getAreas();
    this.getWaiters();
    this.totalRows = this.tables.length;
    let permission = JSON.parse(localStorage.getItem("currentUser")).permission;
    this.permission = {
      is_add: permission.is_add,
      is_deleted: permission.is_deleted,
      is_edit: permission.is_edit,
      web_access_enabled: permission.web_access_enabled
    };
  },
  beforeDestroy() {
    document.removeEventListener("click", this.close);
  }
};
</script>
